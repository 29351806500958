
import { Component, Mixins, Watch } from "vue-property-decorator";
import { Tabbar, TabbarItem } from "vant";
import { Mixin } from "@/core/mixins/mixin";
import { Route } from "vue-router";
import { monitorEvent } from "@/utils/youMengMonitor";

declare type Menu = {
  name: string;
  title: string;
  active: string;
  inactive: string;
  type: number;
};

@Component({
  name: "mainLayout",
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
})
export default class MainLayout extends Mixins(Mixin) {
  bottomTabName = "home";
  themeData: cms.ThemeDto | undefined = {
    bgColor: "#fff",
  };
  isgreyTheme = false;
  /**
   * 进入 MainLayout 时根据当前路由更新一下 bottomTabName
   * 有些情况不是通过点击 Tab 进入这三个页面的
   * 这时无法通过 van-tabbar 的 v-modle 更新 bottomTabName
   * 嵌套路由，父路由，keep-alive 时不会触发 activated，因此需要在 changeRoute 中执行一下
   */
  @Watch("$route")
  changeRoute(to: Route): void {
    this.updateBottomTabNameByRoute(to);
    if (
      this.$route.name == "home" ||
      this.$route.name == "mall" ||
      this.$route.name == "classify" ||
      this.$route.name == "mine"
    ) {
      this.getTheameGray();
    }
  }

  /**
   * 进入 MainLayout 时根据当前路由更新一下 bottomTabName
   * 有些情况不是通过点击 Tab 进入这三个页面的
   * 这时无法通过 van-tabbar 的 v-modle 更新 bottomTabName
   * 第一次进入页面不会触发 changeRoute，所以要在 activated 中执行一下
   */
  activated(): void {
    this.updateBottomTabNameByRoute(this.$route);
    this.getTheameGray();
  }
  /**
   * 根据路由更新 bottomTabName
   * bottomTabName 应该与当前路由保持一致
   *
   * @param route 当前路由
   */
  updateBottomTabNameByRoute(route: Route): void {
    let routeName = route.name;
    if (routeName) {
      this.bottomTabName = routeName;
    }
  }

  public icon = {
    active: "https://img01.yzcdn.cn/vant/user-active.png",
    inactive: "https://img01.yzcdn.cn/vant/user-inactive.png",
  };
  public menusCopy: Array<Menu> = [
    {
      name: "home",
      title: "首页",
      active: require("@/assets/images/TabIcons/icon-home-active.png"),
      inactive: require("@/assets/images/TabIcons/icon-home.png"),
      type: 1,
    },
    {
      name: "find",
      title: "发现",
      active: require("@/assets/images/TabIcons/icon-find-active.png"),
      inactive: require("@/assets/images/TabIcons/icon-find.png"),
      type: 2,
    },
    {
      name: "classify",
      title: "演出",
      active: require("@/assets/images/TabIcons/icon-show-active.png"),
      inactive: require("@/assets/images/TabIcons/icon-show.png"),
      type: 5,
    },
    {
      name: "mine",
      title: "我的",
      active: require("@/assets/images/TabIcons/icon-mine-active.png"),
      inactive: require("@/assets/images/TabIcons/icon-mine.png"),
      type: 4,
    },
  ];

  get style(): { paddingBottom: string } {
    return {
      paddingBottom: this.MenuModule.show ? "48px" : "10px",
    };
  }

  public menuClick(name: string): void {
    if (name == "mine") {
      monitorEvent("Click_MinePageTab", "点击我的tab"); // 埋点：点击我的tab
    }
    if (name === "find") {
      window.location.href = location.origin + "/third/#/yuboya";
    } else {
      this.$router.push({ path: `/${name}` }).catch((err) => err);
    }
  }

  get menus(): Array<Menu> {
    const theme = this.HomeDataModule.theme;
    if (!theme) {
      return this.menusCopy;
    }
    const themeNavigations = theme.themeNavigations;
    if (!themeNavigations) {
      return this.menusCopy;
    }
    if (themeNavigations.length < 1) {
      return this.menusCopy;
    }
    return this.menusCopy.map((menu) => {
      const newMenu = Object.assign({}, menu);
      const menuInfo = themeNavigations.find(
        (f) => Number(f.type) === Number(menu.type)
      );
      if (menuInfo) {
        if (menuInfo.isSelectedUrl) {
          newMenu.active = menuInfo.isSelectedUrl;
        }
        if (menuInfo.notSelectedUrl) {
          newMenu.inactive = menuInfo.notSelectedUrl;
        }
      }
      return newMenu;
    });
  }

  getTheameGray(): void {
    this.InterfaceCacheModule.getData({
      cacheKey: "commonInfo.isThemeGray",
      apiMethod: this.$api.cmsApi.commonInfo.isThemeGray,
      callback: ({ data }) => {
        this.isgreyTheme = data;
        if (this.isgreyTheme) {
          document.getElementsByTagName("html")[0].className += "greyTheme";
        }
      },
    });
  }
}
